const host = process.env.NODE_ENV === 'production' ? "https://api.eauthenticity.com" : "https://localhost:9000";

const Config = {

    client_url: `${host}/client/detail/{id}`,
    message_url: `${host}/message/active`,
    product_url: `${host}/product/{id}`,
    source_url: `${host}/sources`,
    request_otp_url: `${host}/otp/request`,
    validate_otp_url: `${host}/otp/validate`,
    basic_info_url: `${host}/customer/profile`,
    pincode_url: `${host}/pincode`,
    profile_url: `${host}/profile`,
}

export const PATHS = {
    LANDING: `/`,
    SCAN: `/product/scan`,
    VALIDATE: `/validate`,
    BASIC_DETAILS: `/basic/details`,
    THANK_YOU: `/thankyou`,
}

export default Config;