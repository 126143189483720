import Config from '../Config';
import {RestService} from './RestService';

export default {

    getSourceTypes: async function(headers = {}) {
        return RestService.get(Config.source_url, headers)
                .then(response => response.json());
    },
    getClientDetails: async function(id, headers = {}) {
        return RestService.get(Config.client_url.replace("{id}", id), headers)
        .then(response => response.json());
    },
    getMessage: async function(headers = {}) {
        return RestService.get(Config.message_url, headers)
        .then(response => response.json());
    },
    getProductDetails: async function(id, headers = {}) {
        return RestService.get(Config.product_url.replace("{id}", id), headers)
        .then(response => response.json());
    },
    getProfile: async function(id, headers = {}) {
        return RestService.get(Config.profile_url, headers)
        .then(response => response.json());
    },
    getPincodeList: async function(filter){
        return RestService.get(`${Config.pincode_url}?filter=${filter}`, {})
        .then(response => response.json());
    },
};