import React, { useContext } from "react";
import "../css/login.css";
import { 
  Grid, 
 } from "@material-ui/core";
import { AppContext } from "../AppContextProvider";

export default function Landing(props){

  const {setTitle, 
    mobileView
  } = useContext(AppContext);


  React.useEffect(() => {
    setTitle('Welcome');
  }, [true])

  return (
    <div className="Login" style={{height: '100vh', marginLeft: mobileView ? 0 : 16}}>
      <Grid container direction="row" justify="center" alignItems="center" style={{height: '100vh'}}>
        {/* <Grid item xs={12} lg={4}>
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo" width={mobileView ? 150: 450}/>
        </Grid> */}
      </Grid>
    </div>
  );
}