import React, { createContext, useState } from "react";
import theme from "./theme";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { PATHS } from "./Config";

const AppContext = createContext(null);

const defaultTheme = theme;

let user = JSON.parse(localStorage.getItem("eauthenticity.customer.user"));
let steps = JSON.parse(localStorage.getItem("eauthenticity.customer.steps"));
let c = localStorage.getItem("eauthenticity.customer.c");
let p = localStorage.getItem("eauthenticity.customer.p");
let s = localStorage.getItem("eauthenticity.customer.s");
const AppContextProvider = ({ props, children }) => {
  const history = useHistory();
  let [state, setState] = useState({
    isAuthenticated: false,
    isAuthenticating: true,
    user: user,
    loading: false,
    linearLoading: false,
    show: false,
    sb: {
      open: false,
      autohide: 6000,
    },
    openDialog: false,
    openDialog_1D: false,
    open: false,
    title: "",
    theme: defaultTheme,
    progressOpen: false,
    progressMessage: null,
    steps: steps ? steps : {},
    clientId: c,
    productId: p,
    sequence: s,
  });

  const setAuthenticating = (isAuthenticating) => {
    setState((prevState) => ({
      ...prevState,
      isAuthenticating: isAuthenticating,
    }));
  };

  const setLinearLoading = (loading) => {
    setState((prevState) => ({
      ...prevState,
      linearLoading: loading,
    }));
  };

  const closeConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      openDialog: false,
      showfield: false,
      fieldHint: "",
      fieldValue: "",
      dialog_field_text: undefined,
    }));
  };
  
  const closeConfirmDialog1D = () => {
    setState((prevState) => ({
      ...prevState,
      openDialog_1D: false,
    }));
  };

  const handleSBClose = () => {
    setState((prevState) => ({
      ...prevState,
      sb: {
        ...prevState.sb,
        open: false,
        message: "",
        varient: "info",
        autohide: 6000
      },
    }));
  };

  const showSnackBar = (message, variant, autohide) => {
    setState((prevState) => ({
      ...prevState,
      sb: {
        ...prevState.sb,
        open: true,
        message: message,
        varient: variant,
        autohide: autohide || 6000
      },
    }));
  };

  const showConfirmDialog1D = (
    dialog_message,
    dialog_title,
    confirmAction,
    button_title = "OK"
  ) => {
    setState((prevState) => ({
      ...prevState,
      dialog_message_1D: dialog_message,
      dialog_title_1D: dialog_title,
      openDialog_1D: true,
      confirmAction_1D: confirmAction,
      button_title_1D: button_title
    }));
  };

  const showConfirmDialog = (
    dialog_message,
    dialog_title,
    confirmAction,
    cancel_title = "Cancel",
    accept_title = "Confirm",
    showfield = false,
    fieldHint = "",
    fieldValue = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      dialog_message: dialog_message,
      dialog_title: dialog_title,
      openDialog: true,
      confirmAction: confirmAction,
      cancel_title: cancel_title,
      accept_title: accept_title,
      showfield: showfield,
      fieldHint: fieldHint,
      fieldValue: fieldValue,
      dialog_field_text: fieldValue,
    }));
  };

  const setConfirmDialogError = (
    fieldError = undefined
  ) => {
    setState((prevState) => ({
      ...prevState,
      fieldError: fieldError
    }));
  };
  

  const setDialogFieldText = (text) => {
    setState((prevState) => ({
      ...prevState,
      dialog_field_text: text,
    }));
  };

  const setTitle = (title) => {
    setState((prevState) => ({
      ...prevState,
      title: title,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  const handleShow = () => {
    setState((prevState) => ({
      ...prevState,
      show: true,
    }));
  };

  const isLoading = (loading) => {
    setState((prevState) => ({
      ...prevState,
      loading: loading,
    }));
  };

  const userHasAuthenticated = (authenticated) => {
    setState((prevState) => ({
      ...prevState,
      isAuthenticated: authenticated,
    }));
  };

  const handleLogout = (event) => {
    userHasAuthenticated(false);
    localStorage.removeItem("eauthenticity.customer.user");
    localStorage.removeItem("eauthenticity.customer.session");
    history.push(PATHS.LANDING);
  };

  const showProgressDialog = (message = null) => {
    setState((prevState) => ({
      ...prevState,
      progressOpen: true,
      progressMessage: message,
    }));
  };

  const updateProgressMessage = (message = null) => {
    setState((prevState) => ({
      ...prevState,
      progressMessage: message,
    }));
  };

  const closeProgressDialog = () => {
    setState((prevState) => ({
      ...prevState,
      progressOpen: false,
      progressMessage: null,
    }));
  };

  const updateUser = (user) => {
    setState((prevState) => ({
      ...prevState,
      user: user,
    }));
  };

  const setClientId = (clientId) => {
    setState((prevState) => ({
      ...prevState,
      clientId,
    }));
    localStorage.setItem("eauthenticity.customer.c", clientId)
  };

  const setProductId = (productId) => {
    setState((prevState) => ({
      ...prevState,
      productId,
    }));
    localStorage.setItem("eauthenticity.customer.p", productId)
  };

  const setSequence = (sequence) => {
    setState((prevState) => ({
      ...prevState,
      sequence,
    }));
    localStorage.setItem("eauthenticity.customer.s", sequence)
  };
  
  const setMobile = (mobile) => {
    setState((prevState) => ({
      ...prevState,
      mobile,
    }));
  };
  const setToken = (token) => {
    localStorage.setItem('eauthenticity.customer.session', token);
  };

  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  // return useMediaQuery('(max-width:750px)');
  // return useMediaQuery(theme.breakpoints.down('md'));

  const setStep = (step, status) => {
    let steps = status.steps;
    steps = {...steps, [step]: status}
    setState((prevState) => ({
      ...prevState,
      steps,
    }));
    localStorage.setItem("eauthenticity.customer.steps", JSON.stringify(steps))
  }

  const clearStorage = () => {
    localStorage.removeItem("eauthenticity.customer.steps")
    localStorage.removeItem("eauthenticity.customer.session")
    localStorage.removeItem('eauthenticity.customer.c')
    localStorage.removeItem('eauthenticity.customer.p')
    localStorage.removeItem('eauthenticity.customer.s')
    setState((prev) => ({...prev, steps: {}}))
  }

  return (
    <AppContext.Provider
      value={{
        state,
        mobileView,
        steps: state.steps,
        setStep,
        clearStorage,
        handleClose,
        handleSBClose,
        handleShow,
        setTitle,
        showConfirmDialog,
        setConfirmDialogError,
        showSnackBar,
        closeConfirmDialog,
        setLinearLoading,
        isLoading,
        userHasAuthenticated,
        handleLogout,
        updateUser,
        setAuthenticating,
        showProgressDialog,
        updateProgressMessage,
        closeProgressDialog,
        setDialogFieldText,
        showConfirmDialog1D,
        closeConfirmDialog1D,
        setClientId,
        setProductId,
        setSequence,
        setMobile,
        token: localStorage.getItem('eauthenticity.customer.session'),
        setToken,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
