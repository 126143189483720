import React from "react";
import moment from "moment";
import "moment-timezone";
import {
  TableCell,
  makeStyles,
  withStyles,
  Tooltip,
  Chip,
} from "@material-ui/core";
import { STATUS } from "./Constants";
import { blue, green } from "@material-ui/core/colors";

const formatter = new Intl.NumberFormat("en-IN");

export function randomHex() {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_export = "DD-MM-YYYY HH:mm:ss";
export const format_fancy = "Do MMM YYYY HH:mm:ss";
export const format_display_withtime = "Do MMM YYYY HH:mm:ss a";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
// export const format_export = "DD/MM/YY";
export const format_display_date = "Do MMM YYYY HH:mm";
export const format_display_fancy_notime = "Do MMM YYYY";
export const format_display_fancy_my = "MMM YYYY";
export const format_display_fancy_notime_withDay = "dddd, Do MMM";
export const format_display_fancy_notime_withDayAndyear = "dddd, Do MMM YYYY";

export function getTimeInIST(dateString) {
  const timeInUTC = moment.utc(dateString, format);
  return timeInUTC.clone().tz("Asia/Kolkata");
}

export function currentTimeInIST() {
  return moment.utc().clone().tz("Asia/Kolkata");
}

export function formatQty(number) {
  return formatter.format(number);
  // return parseFloat(number).toLocaleString('en-IN', {style: 'unit', minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export function toInt(n) {
  return parseInt(n, 10);
}

export function formatNumber(number) {
  return parseFloat(number).toLocaleString("en-IN", {
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "INR",
  });
}

export function formatNumberNoSym(number) {
  return parseFloat(number).toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function toPercentage(portion, total) {
  return parseFloat((portion / total) * 100).toFixed(1) + "%";
}

export function isInt(n) {
  return n % 1 === 0;
}

export const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: "1px solid rgba(238, 238, 238, 1)",
    fontSize: 14,
    height: 35,
  },
}))(TableCell);

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

export function precision(number) {
  return parseFloat(number.toFixed(2));
}

export function validMobileNo(no) {
  return no && no.length === 10;
}

export function validateEmail(mail) {
  var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (mailformat.test(mail)) {
    return true;
  }
  return false;
}

export function increaseby(figure = 0, percentage = 0) {
  return figure * (1 + percentage / 100);
}

export function openLink(link) {
  window.open(link, "_blank");
}

export function getStatusBadge(status) {
  let color;
  let text;
  switch (status) {
    case STATUS.STARTED:
      text = "Started";
      color = green[500];
      break;
    case STATUS.CLOSED:
      text = "Finished";
      color = blue[500];
      break;
    case STATUS.REJECTED:
      text = "Rejected";
      color = "secondary";
      break;
    default:
      break;
  }
  return (
    <Chip
      size="small"
      label={text}
      style={{ backgroundColor: color, color: "white" }}
    />
  );
}
