import React, { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";
import "../css/ThankYou.css";
import MasterService from "../Services/MasterService";

export default function ThankYou(props) {
  const { state, setStep } = useContext(AppContext);

  const [message, setMessage] = useState("");
  const [product, setProduct] = useState(null);
  const [client, setClient] = useState(null);

  const [website, setWebsite] = useState(null);
  const [whatsapp, setWhatsapp] = useState(null);
  const [amazon, setAmazon] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [instagram, setInstagram] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const [telegram, setTelegram] = useState(null);
  const [pinterest, setPinterest] = useState(null);
  const [youtube, setYoutube] = useState(null);
  const [customerCare, setCustomerCare] = useState(null);
  const [email, setEmail] = useState(null);
  const [flipkart, setFlipkart] = useState(null);
  const [googleBusiness, setGoogleBusiness] = useState(null);

  React.useEffect(() => {
    setStep("thankyou", true);
    Promise.all([
      MasterService.getClientDetails(state.clientId),
      MasterService.getProductDetails(state.productId),
      MasterService.getMessage(),
      MasterService.getProfile(),
    ])
      .then((data) => {
        if (data[0]) {
          setClient(data[0]);
        }
        if (data[1]) {
          setProduct(data[1]);
        }
        if (data[2]) {
          setMessage(data[2].description);
        }
        if (data[3]) {
          // setProfile(data[3]);
          let profile = data[3];
          setWebsite(profile.find(p => p.type === "profile"))
          setWhatsapp(profile.find(p => p.type === "whatsapp"))
          setAmazon(profile.find(p => p.type === "amazon"))
          setTwitter(profile.find(p => p.type === "twitter"))
          setInstagram(profile.find(p => p.type === "instagram"))
          setFacebook(profile.find(p => p.type === "fb"))
          setTelegram(profile.find(p => p.type === "telegram"))
          setPinterest(profile.find(p => p.type === "pinterest"))
          setYoutube(profile.find(p => p.type === "youtube"))
          setCustomerCare(profile.find(p => p.type === "customercare"))
          setEmail(profile.find(p => p.type === "email"))
          setFlipkart(profile.find(p => p.type === "flipkart"))
          setGoogleBusiness(profile.find(p => p.type === "google"))
        }
      })
      .catch((error) => console.log(error));
  }, [true]);

  return (
    <div style={{ textAlign: "center" }}>
      {/* <img
        style={{ marginTop: 100, marginBottom: 20 }}
        src={process.env.PUBLIC_URL + '/images/logo.png'}
        alt="EAuthenticity Logo"
      /> */}
      <header className="site-header" id="header">
        <h1 className="site-header__title" data-lead-id="site-header-title">
          THANK YOU!
        </h1>
      </header>
      {/* <img style={{marginTop: 20}} src="https://omin.b-cdn.net/wp-content/uploads/2019/11/organixmantra_logo_4.png" alt="organixmantra_logo_4" width="100" /> */}
      {client && client.logo_url && (
        <img
          style={{ marginTop: 20 }}
          src={client.logo_url}
          alt="organixmantra_logo_4"
          width="100"
        />
      )}

      <div className="main-content">
        <i className="fa fa-check main-content__checkmark" id="checkmark"></i>

        <p style={{ color: "green", fontSize: 20, marginTop: 20 }}>
          Congratulations! Your product is authentic.
        </p>
        {/* "https://omin.b-cdn.net/wp-content/uploads/2020/05/vitamin-c-serum-1-scaled.jpg" */}
        {product && product.image_url && (
          <img
            src={product.image_url}
            style={{ width: 200, height: 200 }}
            alt="product"
          />
        )}
        <p
          className="main-content__body"
          style={{ marginTop: 20 }}
          data-lead-id="main-content-body"
        >
          {message}
        </p>
        {/* <p className="main-content__body" data-lead-id="main-content-body">Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today.</p> */}
      </div>
      <div align="center" className="links-container">
        {
          website && (
            <a href={website.content} target="_blank" rel="noopener noreferrer" className="fas fa-globe-asia"> </a>
          )
        }
        {
          whatsapp && (
            <a href={whatsapp.content} target="_blank" rel="noopener noreferrer" className="fab fa-whatsapp"> </a>
          )
        }
        {
          amazon && (
            <a
              href={amazon.content} target="_blank" rel="noopener noreferrer" className="fab fa-amazon"> </a>
          )
        }
        {
          twitter && (
            <a href={twitter.content} target="_blank" rel="noopener noreferrer" className="fab fa-twitter"> </a>
          )
        }
        {
          instagram && (
            <a href={instagram.content} target="_blank" rel="noopener noreferrer" className="fab fa-instagram"> </a>
          )
        }
        {
          facebook && (
            <a href={facebook.content} target="_blank" rel="noopener noreferrer" className="fab fa-facebook-f"> </a>
          )
        }
        {
          telegram && (
            <a href={telegram.content} target="_blank" rel="noopener noreferrer" className="fab fa-telegram-plane"> </a>
          )
        }
        {
          pinterest && (
            <a href={pinterest.content} target="_blank" rel="noopener noreferrer" className="fab fa-pinterest-p"> </a>
          )
        }
        {
          youtube && (
            <a href={youtube.content} target="_blank" rel="noopener noreferrer" className="fab fa-youtube"> </a>
          )
        }
        {
          customerCare && (
            <a href={`tel:${customerCare.content}`} target="_blank" rel="noopener noreferrer" className="fas fa-headset"> </a>
          )
        }
        {
          email && (
            <a href={`mailto:${email.content}`} target="_blank" rel="noopener noreferrer" className="fas fa-envelope-open-text"> </a>
          )
        }
        {
          flipkart && (
            <a href={flipkart.content} ><img  alt="Flipkart" class="image--cover" style={{height: 76, marginTop: -15, padding: 2}} src={process.env.PUBLIC_URL + '/images/fa-flipkart.png'}/> </a>
          )
        }
        {
          googleBusiness && (
            <a href={googleBusiness.content}>
              <div style={{height: 70, padding: 2, backgroundColor: '#DDD'}} className="fab">
                <img alt="Google My Business" class="image--cover2" style={{marginLeft: -16, marginTop: -5}} height="77" width="100" src={process.env.PUBLIC_URL + '/images/fa-google-my-business.png'}/>
              </div>
            </a>
          )
        }
      </div>
      <footer className="site-footer" id="footer">
        <p className="site-footer__fineprint" id="fineprint">
          © Organix Mantra 2021 | All Rights Reserved
        </p>
      </footer>
    </div>
  );
}
