import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";
import queryString from 'query-string';
import { validMobileNo } from "../Utils";
import ScanService from "../Services/ScanService";
import { PATHS } from "../Config";

export default function Scan(props){
  const history = useHistory();
  const {
    state,
    setTitle,
    setProductId,
    setClientId,
    setSequence,
    setMobile,
    setToken,
    setStep,
    steps,
    clearStorage
  } = useContext(AppContext);

  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({mobile: ''})


  React.useEffect(() => {
    setTitle('Welcome');
    console.log(props.location.search);
    if(props.location.search){
      clearStorage()
      let qString = queryString.parse(props.location.search);
      const id = qString.id || null;
      if(id && id.split(',').length === 3){
        const ids = id.split(',')
        setClientId(ids[0])
        setProductId(ids[1])
        setSequence(ids[2])
        
      } else {
        history.push(PATHS.LANDING)
      }
    } else {
      if(!steps.validate){
        history.push(PATHS.VALIDATE)
      } else if(!steps.basic){
        history.push(PATHS.BASIC_DETAILS)
      } else if(!steps.thankyou){
        history.push(PATHS.THANK_YOU)
      } else {
        history.push(PATHS.LANDING)
      }
    }
  }, [true])

  const handleChange = e => {
    e.persist()
    setFormData(formData => ({...formData, [e.target.name]: e.target.value}))
  }

  const requestOTP = (e) => {
    if(validMobileNo(formData.mobile)) {
      setMobile(formData.mobile)
      setMessage('')
      ScanService.requestOTP(formData.mobile, null, state.clientId, state.productId, state.sequence)
      .then(data => {
        if(data.success) {
          setToken(data.token);
          setStep('scan', true)
          history.push(PATHS.VALIDATE);
        }
      })
      .catch(error => {
        setMessage('Something went wrong. Please try again.')
      })
    } else {
      setMessage("Please enter valid mobile number")
    }
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      requestOTP()
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center container">
        <div className="card py-5 px-3" style={{height: 'auto'}}>
          <h5 className="m-0">Enter Mobile Number</h5>
          {/* <span className="mobile-text">Enter Mobile number so that we can send the verification code.</span> */}
            <br/>
            <div className="d-flex flex-row mt-4">
                <input type="text" className="form-control" disabled placeholder="+91" value={"+91"} style={{width: '24%'}}/>
                <input type="tel" name="mobile" pattern="\d*" className="form-control" autoFocus onChange={handleChange} maxLength="10" onKeyDown={_handleKeyDown} style={{width: '100%', marginRight: 0}}/>
            </div>
            <div className="text-danger text-right mr-3">
              <label>{message}</label>
            </div>
            <div className="text-center mt-5">
              <span className="font-weight-bold text-danger cursor" style={{fontSize: 18}} onClick={requestOTP}>
                NEXT
              </span>
            </div>
        </div>
    </div>
  );
}