import React from "react";
import { Route, Switch } from "react-router-dom";
import Landing from "./LandingPage";
import Scan from "./ScanPage";
import Validate from "./ValidatePage";
import NotFound from "./NotFound/NotFound";
import BasicDetails from "./BasicDetailsPage";
import ThankYou from "./ThankYouPage";
import { PATHS } from "./Config";

export default () =>
  <Switch>
    <Route path={PATHS.LANDING} exact component={Landing} />
    <Route path={PATHS.SCAN} exact component={Scan} />
    <Route path={PATHS.VALIDATE} exact component={Validate} />
    <Route path={PATHS.BASIC_DETAILS} exact component={BasicDetails} />
    <Route path={PATHS.THANK_YOU} exact component={ThankYou} />
    
    {/* Not found */}
    <Route component={NotFound} />
  </Switch>;