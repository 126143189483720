import React, { useContext } from "react";
import OtpInput from 'react-otp-input';
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";
import ScanService from "../Services/ScanService";
import { PATHS } from "../Config";

export default function Validate(props){
  const history = useHistory();
  const {
    state,
    token,
    setStep,
    steps,
  } = useContext(AppContext);
  const otpLength = 4;
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({otp: ''})


  React.useEffect(() => {
    if(!token){
      history.push(PATHS.LANDING)
    }
    if(steps && steps.validate){
      if(!steps.basic){
        history.push(PATHS.BASIC_DETAILS)
      } else if(!steps.thankyou){
        history.push(PATHS.THANK_YOU)
      } else {
        history.push(PATHS.LANDING)
      }
    }
  }, [true])

  const handleChange = otp => {
    setFormData(formData => ({...formData, otp}))
    setMessage('')
  }

  const resendOTP = (e) => {
    setMessage('')
    ScanService.requestOTP(state.mobile, state.source, state.clientId, state.productId, state.sequence)
    .then(data => {
      if(data.success) {
        setMessage('OTP Resent')
      }
    })
    .catch(error => {
      setMessage(error.message)
    })
  }
  
  const validateOTP = (e) => {
    if(formData.otp.length === otpLength) {
      setMessage('')
      ScanService.validateOTP(formData.otp)
      .then(data => {
        if(data.success) {
          setStep('validate', true)
          history.push(PATHS.BASIC_DETAILS);
        } else {
          setMessage(data.message)
        }
      })
      .catch(error => {
        setMessage(error.message)
      })
    } else {
      setMessage("Please enter valid OTP")
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center container">
      <div className="d-flex justify-content-center align-items-center container">
          <div className="card py-5 px-3">
              <h5 className="m-0">Mobile phone verification</h5>
              <span className="mobile-text">Enter the code we just send on your mobile phone <b className="text-danger">+91 {state.mobile}</b></span>
              <div className="d-flex flex-row mt-5 justify-content-center">
                  <OtpInput
                    autoFocus={true}
                    value={formData.otp}
                    onChange={handleChange}
                    numInputs={otpLength}
                    inputStyle={"form-control w-3em"}
                    />
              </div>
              <div className="text-danger text-center mr-3">
                <label>{message}</label>
              </div>
              <div className="text-center mt-4" style={{fontSize: 18}}>
                <span className="d-block mobile-text mb-3">Don't receive the code?</span>
                <span className="font-weight-bold text-danger cursor mt-5" onClick={resendOTP}>RESEND</span>
                <span className="d-block mobile-text">or</span>
                <span className="font-weight-bold text-danger cursor mt-5" onClick={validateOTP}>NEXT</span>
              </div>
          </div>
      </div>
    </div>
  );
}