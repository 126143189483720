import Config from '../Config';
import {RestService} from './RestService';

export default {
    requestOTP: async function(mobile, source, client, product, sequence, headers ={}) {
        return RestService.get(`${Config.request_otp_url}?mobile=${mobile}&client=${client}&product=${product}&sequence=${sequence}${source ? `&source=${source}` : ''}`, headers)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else if (
                response.status === 404 ||
                response.status === 409 ||
                response.status === 400 ||
                response.status === 403
            ) {
                return response.json();
            }
        });
    },
    validateOTP: async function(otp, headers ={}) {
        return RestService.get(`${Config.validate_otp_url}?otp=${otp}`, headers)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else if (
                response.status === 404 ||
                response.status === 409 ||
                response.status === 400 ||
                response.status === 403
            ) {
                return response.json();
            }
        });
    },
    submitProfileInfo: async function(body, headers = {}) {
        return RestService.post(Config.basic_info_url, headers, body)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else if (
                response.status === 404 ||
                response.status === 409 ||
                response.status === 400 ||
                response.status === 403
            ) {
                return response.json();
            }
        });
    },
};